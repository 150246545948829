@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  height: 100%;
  margin: 0;
}

body {
  background-color: #1f1f1f; /* Primary dark color */
}

.zoom-container {
  position: relative;
  overflow: hidden;
}

.zoom-container img {
  transition: transform 0.1s ease, transform-origin 1s ease;
}

.zoom-container:hover img {
  transform: scale(1.5);
}


